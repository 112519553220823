/* App.css*/

body {
  font-family: 'Arial', sans-serif;
   margin: 0;
   padding: 0;
   background-image: url('../public/assets/background/GamePageBack.png');
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   height: 100vh;
 }
 
/* LandingPage.css */

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    scroll-behavior: smooth;
  }

  .title-container {
    background-color: #e0e0e0;
    margin-bottom: 200px;
    border: 0.05px solid black;
    border-radius: 0.75rem;
  }

  .button-container {
    margin-top: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .start-button {
    background-color: #4caf50;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .start-button:hover {
    background-color: #45a049;
    transform: scale(1.05); /* Adjust the scale for a slight zoom effect on hover */
  }


/* LoginPage.css */

/* ... your existing styles ... */

.input-container {
  position: relative;
}

.toggle-password-button {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  font-size: 18px; /* Adjust the font size as needed */
  color: #555; /* Adjust the color as needed */
}

/* Adjust the icon color on button hover */
.toggle-password-button:hover {
  color: #333;
}


.login-page{
    display: flex;
    align-items: center;
    flex-direction: column;
}


.login-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    background: white;
    margin-top: 50px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    max-width: 500px;
  }



.input-container {
    margin-bottom: 15px;
  }


.input {
    width: 100%;
    padding: 8px;
    margin: 5px 0 15px 0;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 4px;
  }

.login-button{
    background-color: #4caf50;
    color: white;
    padding: 10px;
    margin-right:25px;
    margin-left:35px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.login-button:hover {
    background-color: #45a049;
    transform: scale(1.05); /* Adjust the scale for a slight zoom effect on hover */
  }
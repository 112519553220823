/* Dailog.css */

  .title{
    font-weight: bold;
    font-size: xx-large;

  }

.dialog-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dialog-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: white;
    padding: 20px;
    border-radius: 8px;
    position: relative;
  }


  .edit-button{
    background-color: #4caf50;
    color: white;
    padding: 10px;
    margin-right:25px;
    margin-left:35px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}


  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background: none;
    border: none;
    font-size: 18px;
    color: #555;
  }
  
  .dailog-input {
    width: 100%;
    padding: 8px;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    border-radius: 4px;
  }



.button:hover {
  background-color: #45a049;
  transform: scale(1.05); /* Adjust the scale for a slight zoom effect on hover */
}

.leaderboard-dialog table {
  width: 100%;
  border-collapse: collapse; /* Collapse borders to avoid doubled borders */
}

.leaderboard-dialog th, .leaderboard-dialog td {
  border: 1px solid #ddd; /* Border color and width */
  padding: 8px; /* Padding for content within cells */
  text-align: left; /* Adjust text alignment as needed */
}

.leaderboard-dialog th {
  background-color: #f2f2f2; /* Background color for header cells */
}
/* Dialog.css */

/* Dialog.css */

.leaderboard-table {
  max-height: 200px; /* Set the maximum height of the table */
  overflow-y: auto; /* Enable vertical scrolling if needed */
}

/* Add any additional styling for the table as needed */
.leaderboard-table table {
  width: 100%;
  border-collapse: collapse;
}

.leaderboard-table th, .leaderboard-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.leaderboard-table th {
  background-color: #f2f2f2;
}

